div#comment-analytics-section {
  background-color: #f4eeff;
  border-radius: 14px;
  display: flex;
  flex-direction: column;
  max-height: 1000px;
  padding: 18px;
}

div#comment-analytics-section ul#comment-list {
  width: 100%;
  list-style: none;
  padding-right: 15px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 35px;
  overflow: scroll;
  align-content: flex-start;
  justify-content: start;
  padding-bottom: 14px;
}

div.comment-container {
  position: relative;
}

div#comment-analytics-section ul#comment-list li {
  background-color: white;
  margin: 0px 0px 0px 0px;
  border-radius: 8px;
  padding: 7px 12px;
  font-size: 13px;
  height: 110px;
  align-self: start;
  color: #915EDE;
  border: 1px solid white;
  font-weight: 500;
  overflow-y: scroll;
}

/* div#comment-analytics-section ul#comment-list li:hover {
  cursor: pointer;
  border: 1px solid #670ff7;
} */

div#comment-analytics-section ul#comment-list li.selected {
  background-color: #670ff7;
  color: white;
  border: 1px solid #670ff7;
}

div#data-holder-comment {
  width: 50%;
  padding: 20px;
}

div#data-holder div#commentVisuals {
  background-color: white;
  padding: 30px;
  height: 100%;
  border-radius: 8px;
}

div#metrics {
  display: flex;
  flex-direction: row;
}

div#metrics div {
  flex: 40;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px solid #670ff7;
  font-size: 14px;
  font-weight: 600;
  color: #670ff7;
  border-radius: 8px;
  padding: 10px;

  margin: 20px 10px;
  min-height: 51px;
}

div.yukulele {
  font-size: 25px;
  color: orange;
  font-weight: 600;
}

.placeholder-comments-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.placeholder-questions-image{
  height:150px;
}

.placeholder-questions-label{
  font-size: 14px;
  color: #915EDE;
  text-align: center;
  margin: 10px;
}
.placeholder-questions-label-title{
  font-size: 20px;
  font-weight: bold;
  color: #915EDE;
  text-align: center;
  margin-top: 12px;

}

.placeholder-question-list{
  width: 100%;
  height: 30%;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.placeholder-question-list-line{
  width: 80%;
  height: 12%;
  border-radius: 10px;
  margin: 7px;
  background: #F4EFFF;
}

#comments-searchbar-row {
  position: relative;
}

#comments-searchbar {
  width: 220px;
  border-radius: 10px;
  font-size: 16px;
  padding: 0.5rem;
  border: 1.5px solid #670ff7;
  margin: 5px 0px 20px 0px;
  font-family: "DM Sans";
}

#comments-searchbar::placeholder {
  padding-left: 24px;
}

#comments-searchbar-magnifying-glass {
  position: absolute;
  left: 10px;
  top: 13px;
  max-width: 16px;
}

.comment-header-row {
  display: flex;
  color: #670ff7;
  justify-content: space-between;
  font-size: 12px;
  line-height: 20px;
  border-bottom: 1px solid #ece5f8;
  margin-bottom: 3px;
}

.comment-bubble-triangle {
  position: absolute;
  border-radius: 2px;
  bottom: -10px;
  left: 10px;
  transform: rotate(300deg);
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 16px solid #fff; /* Adjust the color as needed */
}
