
.color-input-picker{
  width: 40px;
  height: 40px;
  opacity: 0;
  cursor: pointer;
  overflow: hidden;
}

.color-input-standin:hover{
  opacity: 0.8;
}

.color-input-standin{
  overflow: hidden;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px 0px 0px 2px;
  border-top: 1px solid #8D00FF;
  border-left: 1px solid #8D00FF;
  border-bottom: 1px solid #8D00FF;
}

.color-input-text{
  width: 60px;
  height: 26px;
  border: none;
  border-radius: 0px 3px 3px 0px;
  text-align: center;
  color: #670FF7;
  font-size: 11px;

  /* font-family: "DMSans-Regular"; */
  box-shadow: inset 0px 0px 1px 1px rgba(0,0,0,0.1);
  border-top: 1px solid #8D00FF;
  border-right: 1px solid #8D00FF;
  border-bottom: 1px solid #8D00FF;
}



.color-input-container{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin-left: 8px;
}

