div.admin-body {
  margin-left: 15vw;
  padding: 8vh 5vw;
  width: 100vw;
}

div.admin-body h1 {
  font-size: 40px;
  color: #670ff7;
  margin: 0px;
}

div.admin-body div.widget-holder {
  background-color: #f4eeff;
  padding: 15px;
  border-radius: 14px;
}
