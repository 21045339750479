div#settings-holder {
}

div#top-settings {
  display: flex;
  flex-direction: row;
}

div#save-holder {
  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}

div#save-holder button {
  border-radius: 12px;
  height: 46px;
  font-size: 19px;
  font-weight: 700;
  font-family: "DM Sans", sans-serif;
  border: none;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.11);
  width: 180px;
  display: block;
  margin-bottom: 0px;
}

div#save-holder button:disabled {
  opacity: 0.2;
}

div#left-panel {
  width: 350px;
}

div#wheel-holder {
  background-color: #f4eeff;
  margin-right: 30px;
  border-radius: 14px;
  height: 100%;
  padding-top: 20px;
  box-sizing: border-box;
}

div#wheel-preview {
  background-image: url("../images/phoneframe.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 90%;
  border-radius: 50px;
  padding-top: 2vh;
  margin: 0px auto;
  border: 2px solid #1e1e1e;
  box-shadow: 0px 10px 26px 0px rgba(0, 0, 0, 0.31);
  /* height: 80vh; */
  aspect-ratio: 0.485;
  transition-duration: 0.2s;
}

h2#header-preview {
  font-family: "DM Sans", sans-serif;
  font-size: 19px;
  line-height: 25.25px;
  text-align: left;
  width: 85%;
  margin: 2px auto 0px auto;
  padding-left: 4%;
}

span#title-preview {
  width: auto;
  height: auto;
  font-size: inherit;
  font-family: "DM Sans", sans-serif;
  display: auto;
  position: inherit;
}

div#wheel-preview button.primary {
  border-radius: 7px;
  height: 30px;
  font-size: 12px;
  font-weight: 700;
  font-family: "DM Sans", sans-serif;
  border: none;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.11);
  width: 60%;
  display: block;
  margin-bottom: 20px;
}

div#setting-widgets {
  width: 60%;
}

input.color-select {
  width: 23px;
  height: 23px;
  margin-left: 10px;
  border: none;
  border-radius: 3px;
}

div#color-widget {
  display: flex;
  flex-direction: row;
}

div#left-color-column {
  width: 50%;
  padding: 0px 0px;
  box-sizing: border-box;
}

div#right-color-column {
  width: 50%;
  padding: 0px 10px;
  box-sizing: border-box;
}

div.color-line {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 7px auto;
}

.color-line-label{
  color: #670ff7;
  font-size: 14px;
}


div#widgets-bottom {
  margin-top: 30px;
}

div#social-widget {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 25px 25px 5px 25px;
  position: relative;
}

div#general-widget {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 25px 25px;
}
div.general-row {
  height: 8vh;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
}

div.general-row-value {
  width: 220px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: left;
}


.settings-column-left {
  width: 55%;
  border-right: 1px solid #680ff753;
}

.settings-column-right {
  width: 40%;
}


div.logo-upload {
  display: flex;
  height: 140px;
}

div#general-name {
    color: #55289F;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 0.2px;
    padding: 2px 5px;
    text-transform: uppercase;
}

div#social-widget div.social-row {
  width: 42vw;
  height: 50px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

div#social-widget div.social-column label {
  margin-left: 10px;
}

input.social-url {
  margin: 0px;
  font-size: 14px;
  padding: 8px;
  border-radius: 4px;
  margin-top: 0px;
  border: 1px solid #670ff7;
  width: 220px;
  margin-left: 0px;
  color:#5b5b5b;
}



input.social-url:focus {
  outline: none;
}

input.social-url:disabled {
  background-color: rgb(202, 202, 202);
  opacity: 0.5;
}

div#widget-superbottom {
  margin-top: 30px;
}
div#prize-widget {
  height: 400px;
  display: flex;
  flex-direction: row;
}

div#prize-list {
  overflow: scroll;
  padding-right: 15px;
  width: 50%;
}

div#prize-list ul {
  list-style: none;
}

div#prize-list li {
  background-color: white;
  border: 1px solid #670ff7;
  display: flex;
  flex-direction: row;
  border-radius: 14px;
  padding: 5px 5px;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
  min-height: 50px;
}

div#prize-list li h5 {
  color: #670ff7;
  width: 70%;
  box-sizing: border-box;
  margin: 0px;
  font-size: 14px;
  padding: 5px 0px 5px 5px;
  font-weight: 500;
}

div#prize-list li input {
  width: 30px;
  padding-left: 0%;
  box-sizing: border-box;
  height: 20px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 5px;
  border: 0px solid #670ff7;
  color: #670ff7;
  font-family: "DM Sans", sans-serif;
  text-align:center;
  letter-spacing: -0.5px;
}

.input-arrow-button{
  width: 0; 
  height: 0; 
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  cursor: pointer;
}

.input-arrow-button:hover{
  opacity: 0.8;
}
.input-arrow-up{
  border-bottom: 7px solid #8D00FF;
}

.input-arrow-down{
  border-top: 7px solid #8D00FF;
}

.input-number-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.input-number-text-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.input-number-percent{
  position: relative;
  right: 8px;
  color: #670ff7;
  font-size: 12px;
}

div#prize-list li button {
  height: 20px;
  width: 20px;
  border: none;
  font-size: 80%;
  padding-top: 0px;
  padding-left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

div#prize-list li button:hover{
  opacity: 0.8;
}

div#prize-selection {
  width: 50%;
  padding-left: 15px;
  border-left: 1px solid #680ff71e;
}

div#prize-selection-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

div#prize-selection-header button {
  height: 20px;
  font-size: 13px;
  width: auto;
  padding: 0px;
  font-weight: 600;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #670ff7;
  background-color: transparent;
  background-color: white;
  color: #670ff7;
  font-family: "DM Sans", sans-serif;
  margin: 5px 0px;
}

div#prize-selection ul {
  list-style: none;
  overflow: scroll;
  overflow-x: hidden;
  height: 90%;
  padding: 5px;
}

div#prize-selection ul::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

div#prize-selection ul::-webkit-scrollbar-track {
  background-color: #680ff728;
  border-radius: 5px;
}

div#prize-selection ul::-webkit-scrollbar-thumb {
  background-color: #32185c34;
  border-radius: 5px;
}

div#prize-selection li {
  background-color: #ffffff;
  border-radius: 14px;
  padding: 5px;
  margin-bottom: 8px;
}

div#prize-selection li div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

div#prize-selection li div h5 {
  color: #670ff7;
  padding: 5px;
  width: 60%;
}

div#prize-selection li div button {
  font-family: "DM Sans", sans-serif;
  font-size: 12px;
  width: auto;
  padding: 5px 10px;
  width: auto;
  font-weight: 700;
  height: 27px;
}

div#prize-selection p {
  padding: 5px;
  font-size: 14px;
  width: 90%;
}

h6.prize-list {
  color: #ef2b7a;
  font-size: 14px;
  padding: 8px 5px;
}

div#prize-create {
  padding-top: 20px;
}

div#prize-create label {
  display: block;
  font-size: 12px;
  color: #670ff7;
  font-weight: 500;
}

div#prize-create input,
textarea {
  display: block;
  border: 1px solid #670ff7;
  border-radius: 4px;
  height: 35px;
  padding-left: 5px;
  margin-bottom: 10px;
  font-family: "DM Sans", sans-serif;
}

div#prize-create input#title {
  width: 70%;
}

div#prize-create textarea#description {
  width: 100%;
  height: 100px;
  padding-top: 5px;
}

.single-text-area {
  height: 30px !important;
}

div#prize-create h5#prompt {
  color: #feb910;
  height: 20px;
}

div#prize-create button {
  border-radius: 12px;
  height: 36px;
  font-size: 15px;
  font-weight: 700;
  font-family: "DM Sans", sans-serif;
  border: none;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.11);
  width: 60%;
  float: left;
  margin-top: 20px;
}

/* ----- */

div#question-widget {
  height: auto;
  min-height: 170px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: row;
}

div#question-list {
  padding-right: 15px;
  width: 40%;
}

div#question-list ul {
  list-style: none;
}

div#question-list li {
  background-color: white;
  border: 1px solid #670ff7;
  display: flex;
  flex-direction: row;
  border-radius: 14px;
  padding: 5px 5px;
  justify-content: center;
  align-items: center;
  margin-bottom: 5px;
}

div#question-list li div {
  width: 85%;
}

div#question-list li h5 {
  color: #670ff7;
  width: 100%;
  box-sizing: border-box;
  margin: 0px;
  font-size: 14px;
  padding: 5px 5px 5px 5px;
  font-weight: 500;
}

div#question-list li button {
  height: 20px;
  width: 20px;
  border: none;
  font-size: 20px;
  line-height: 20px;
}

div#question-selection {
  width: 60%;
  padding-left: 15px;
  border-left: 1px solid #680ff71e;
}

div#question-selection-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

div#question-selection-header button {
  height: 20px;
  font-size: 13px;
  width: auto;
  padding: 0px;
  font-weight: 600;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #670ff7;
  background-color: transparent;
  background-color: white;
  color: #670ff7;
  font-family: "DM Sans", sans-serif;
  margin: 5px 0px;
}

div#question-selection ul {
  list-style: none;
  overflow: scroll;
  overflow-x: hidden;
  height: 90%;
  max-height: 400px;
  padding: 5px;
}

div#question-selection ul::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

div#question-selection ul::-webkit-scrollbar-track {
  background-color: #680ff728;
  border-radius: 5px;
}

div#question-selection ul::-webkit-scrollbar-thumb {
  background-color: #32185c34;
  border-radius: 5px;
}

div#question-selection li {
  background-color: #ffffff;
  border-radius: 14px;
  padding: 5px;
  margin-bottom: 8px;
}

div#question-selection li div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

h6.q-type {
  padding: 5px;
}

h6#tag-question {
  color: #feb910;
}

h6#smile-question {
  color: #6fb8af;
}

div#question-selection li div h5 {
  color: #670ff7;
  padding: 5px;
  width: 60%;
}

div#question-selection li div button {
  font-family: "DM Sans", sans-serif;
  font-size: 13px;
  width: auto;
  padding: 5px 10px;
  width: auto;
  font-weight: 700;
  height: 27px;
}

div#question-selection p {
  padding: 5px;
  font-size: 14px;
  width: 90%;
}

h6.question-list {
  color: #ef2b7a;
  font-size: 14px;
  padding: 8px 5px;
}

div#question-create {
  padding-top: 20px;
}

div#question-create label {
  display: block;
  font-size: 12px;
  color: #670ff7;
  font-weight: 500;
}

div#question-create input,
select {
  display: block;
  border: 1px solid #670ff7;
  border-radius: 4px;
  height: 35px;
  padding-left: 5px;
  margin-bottom: 10px;
  font-family: "DM Sans", sans-serif;
}

div#question-create input#text {
  width: 100%;
}

div#question-create select#question-type {
  width: 40%;
}
div#question-create div#tag-factory {
  display: flex;
  flex-direction: row;
  width: 80%;
}

div#question-create div#tag-factory button {
  background-color: #670ff7;
  margin: 0px;
  border-radius: 0px 14px 14px 0px;
  border: none;
  width: 40%;
  height: 34px;
}

div#question-create input#question-tags {
  width: 90%;
  border-right: none;
  border-radius: 4px 0px 0px 4px;
  padding-left: 5px;
  box-sizing: border-box;
}

div#question-create ul#tag-list {
  font-size: 14px;
  font-weight: 500;
  overflow: auto;
}

div#question-create ul#tag-list li {
  color: #670ff7;
  background-color: white;
  border: 1px solid #670ff7;
  display: inline-block;
  margin: 3px;
  padding: 5px;
  padding-left: 5px;
}

div#question-create ul#tag-list li#correction {
  background-color: #670ff7;
  color: white;
  font-weight: 600;
}

div#question-create ul#tag-list li#correction:hover {
  cursor: pointer;
}

div#question-create h5#prompt {
  color: #feb910;
  height: 20px;
}

div#question-create button {
  border-radius: 12px;
  height: 36px;
  font-size: 13px;
  font-weight: 700;
  font-family: "DM Sans", sans-serif;
  border: none;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.11);
  width: 60%;
  float: left;
  margin-top: 20px;
}

ul#display-tag-list {
  font-weight: 500;
  overflow: auto;
}

ul#display-tag-list li {
  color: #670ff7;
  background-color: white;
  border: 1px solid #670ff7;
  display: inline-block;
  margin: 3px;
  padding: 4px;
  padding-left: 5px;
  font-size: 13px;
}

#wheelMockContainer{
  height: 60%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#maxCharQuestionName{
  height: 1vh;
  text-align: end;
  font-size: 10px;
}

.row-switch {
  display: flex;
  flex-direction: row !important;
  margin-bottom: 0px !important;
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switchAction {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 34px;
}

.switch input#slider { 
  opacity: 0;
  width: 0;
  height: 0;
}

.switchAction input.sliderAction { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 5px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  width: 60px;
  height:25px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.sliderActionScale {
  transform: scale(0.7);
}

#slider{
  width: 100px;
  height:90px;
}

input#slider:checked + .slider {
  background-color: #8D00FF;
  width: 60px;
  height:25px;
}

input#slider:focus + .slider {
  box-shadow: 0 0 1px #8D00FF;
  width: 60px;
  height:25px;
}

input#slider:checked + .slider:before {
  
  -webkit-transform: translateX(34px);
  -ms-transform: translateX(34px);
  transform: translateX(34px);
}

/*-- */


.sliderAction{
  width: 100px;
  height:90px;
}

input.sliderAction:checked + .slider {
  background-color: #8D00FF;
  width: 60px;
  height:25px;
}

input.sliderAction:focus + .slider {
  box-shadow: 0 0 1px #8D00FF;
  width: 60px;
  height:25px;
}

input.sliderAction:checked + .slider:before {
  
  -webkit-transform: translateX(34px);
  -ms-transform: translateX(34px);
  transform: translateX(34px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

#delete-wheel-button{
  width: 200px;
  margin-top: 10px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 10px;
  background-color: #ef2b7a;
}

#delete-wheel-button:hover {
  opacity: 0.6;
}
.input-days-container{
  padding-right: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 14px;
}

.input-days{
  width: 100%;
  height: 32px;
  padding-left: 9px;
  border-radius: 5px;
  margin-right: 5px;
  border: 1px solid #670ff7;
  color:#5b5b5b;
}

.input-days-text{
  color:#670ff7;
  display: flex;
  align-items: center;
  justify-content: center;
}

.separation-column{
  position: absolute;
  width: 23%;
  height: 79%;
  border-right: 1px solid #e1dcea;
}

.checkbox-promo-container{
  width: 35%;
  height: 25px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.checkbox-promo{
  margin-top: -10px;
  height: 25px;
}
.label-checkbox{
  border: 1px solid black;
}

.promoIconContainer{
  cursor: pointer;
  width: 50px;
  height: 30px;
  margin-right: 5px;
  margin-left: -50px;
}




select#promo-selector {
  font-size: 14px;
  padding: 10px;
  border-radius: 5px;
  width: 180px;
  font-family: "DM Sans", sans-serif;
  border: 1px solid #670ff7;
  color: #670ff7;
  font-weight: 600;
  margin-top: 10px;
  height: auto;
}

select#promo-selector:focus {
  outline: none;
}

input.wheel-name {
  margin: 0px;
  font-size: 14px;
  padding: 8px;
  border-radius: 4px;
  width: 220px;
  /* margin-left: 26%; */
  color:#5b5b5b;
  border: 1px solid #670ff7;
}

#language-select{
  width: 220px;
  color:#5b5b5b;
}

.delete-wheel-prompt{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99999;

}

.background-overlay{
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: #1e1e1e;
  opacity: 0.5;

}

.delete-wheel-prompt-window{
  width: 400px;
  height: 300px;
  background: #f4eeff;
  border-radius: 8px;
  z-index: 999999;
  padding: 14px 30px 25px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.delete-confirmation-input{
  border: 1px solid #8D00FF;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 100%;
  height: 40px;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  color:rgba(0, 0, 0, 0.81)
}

.delete-buttons-container{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.delete-cancel-button{
  background: #8D00FF;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  padding: 10px;
  border-radius: 7px;
  font-weight: bold;
  cursor: pointer;
}

.delete-cancel-button:hover{
  opacity: 0.7
}

.delete-confirm-button{
  background: #ef2b7a;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120px;
  padding: 10px;
  border-radius: 7px;
  font-weight: bold;
  cursor: pointer;
}

.delete-confirm-button:hover{
  opacity: 0.7
}

.delete-wheel-header{
  font-size: 25px;
}

.delete-wheel-text{
  margin-top: 20px;
  font-size: 13px;
  color:rgba(0, 0, 0, 0.81)
}
.prize-top-row{
  position: relative;
  height: 30px;
}
.prize-add-prize-button{
  position: absolute;
  top: -5px;
  left: -5px;
  background: #8D00FF;
  border-radius: 14px 0px 0px 0px;
  height: 30px;
  width: 92%;
  padding: 5px 0px 0px 15px;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
}

.prize-delete-button{
  position: absolute;
  right: -5px;
  top: -5px;
  background: #ef2b7a;
  border-radius: 0px 14px 0px 0px;
  height: 30px;
  width: 13%;
  padding: 5px 0px 0px 15px;
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
}



.prize-promo-button{
  position: absolute;
  right: 25px;
  top: -5px;
  background: #6fb8af;
  height: 30px;
  width: 26%;
  padding: 5px 0px 0px 15px;
  color: #ffffff;
  font-weight: bold;
  z-index: 80;
  cursor: pointer;
}

.prize-existing-bottom-container{
  display: flex;
  flex-direction: column !important;
  justify-content: center;
  align-items: center;
}

.trash-icon-question-container:hover, .question-add-button:hover, .prize-delete-button:hover, .prize-add-prize-button:hover, .prize-promo-button:hover{
  opacity: 0.9;
}

.prize-existing-name{
  text-align: center;
  color:#8D00FF;
  font-size: 14px;
  font-weight: bold;
  padding: 5px;
}

.prize-existing-line{
  border-top: 1px solid #D2D2D2;
  width: 85%;
  height: 1px;
}

.prize-existing-description{
  color:#616161;
  font-size: 14px;
  padding: 5px;
  word-break: break-word;
}

.promo-icon{
  top: 3px;
  left: 10px;
  position: absolute;
  width: 42px;
}

.promo-codes-number{
  position: absolute;
  width: 25px; 
  height: 12px;
  left: 24px;
  top: 9px;
  z-index: 99;
  font-family: "DM Sans", sans-serif;
  font-weight: bold;
  color:#fff;
  font-size: 10px;
  letter-spacing: -0.8px;
  display: flex;
}

.trash-icon{
  top: 7px;
  right: 8px;
  position: absolute;
  max-height: 16px;
}

.question-button-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 110px;
  position: absolute;
  top: 6px;
  right: 6px;

}

.question-add-button{
  border-radius: 7px 0px 0px 7px !important;
  width: 73% !important; 
  cursor: pointer;
}


.trash-icon-question-container{
  border-radius: 0px 7px 7px 0px !important;
  width: 27% !important;
  height: 27px;
  background: #ef2b7a; 
  position: relative;
  cursor: pointer;
}

.trash-icon-question{
  position: absolute;
  top: 5px;
  left: 8px;
  max-height: 16px;
}


.question-li{
  position: relative;
}

.prize-li{
  margin-bottom: 15px !important;
}

.action-icon{
  max-width: 22px;
}

.action-disabled{
  opacity: 0.4;
}

.action-label{
  display: flex;
  flex-direction: row;
  color: #55289F;
  margin-left: 10px; 
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 2%;
}

.action-label-icon-container{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 45%;
  user-select: none; 
}

.action-label-icon {
  display: flex;
  align-items: center;
}

.action-input-label-container{
  color: #55289F;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 2%;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: flex-start;
}

.custom-input-label-container{
}
.custom-extra-row{
  justify-content: flex-end !important;
}

.actions-separator-line{
  position: absolute;
  /* border-left: 1px solid #e1dcea; */
  height: 250px;
  width: 1px;
  top: 39px;
  left: 38%;
}

.color-input-container-settings{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  margin-left: 8px;
}


input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.wheel-logo-container {
  margin-top: 20px;
  width: 100%;
  padding-left: 28px;
}

.wheel-logo-preview {
  height: 25px;
}

.settings-locked{
  opacity: 0.3;
  cursor: not-allowed;
  pointer-events: none;
}

h4.settings-feature {
  display: flex;
  flex-direction: row;
} 