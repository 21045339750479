div#question-analytics-section {
  background-color: #f4eeff;
  border-radius: 14px;
  display: flex;
  flex-direction: row;
  height: 400px;
  padding: 8px;
}

div#question-analytics-section ul#question-list {
  width: 35%;
  list-style: none;
  border-right: 1px solid #680ff723;
  padding-right: 15px;
  overflow-y: scroll;
  background: transparent;
}

div#question-analytics-section ul#question-list li {
  background-color: white;
  margin: 5px;
  border-radius: 8px;
  padding: 12px 8px;
  color: #670ff7;
  border: 1px solid white;
  font-weight: 500;
}

div#question-analytics-section ul#question-list li:hover {
  cursor: pointer;
  border: 1px solid #670ff7;
}

div#question-analytics-section ul#question-list li.selected {
  background-color: #670ff7;
  color: white;
  border: 1px solid #670ff7;
}

div#data-holder {
  width: 65%;
  padding: 20px;
}

div#data-holder div#visuals {
  background-color: white;
  padding: 30px;
  height: 80%;
  border-radius: 8px;
  padding-left: 5px;
}

div#metrics {
  display: flex;
  flex-direction: row;
}

div#metrics div {
  flex: 40;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px solid #670ff7;
  font-size: 14px;
  font-weight: 600;
  color: #670ff7;
  border-radius: 8px;
  padding: 10px;

  margin: 20px 10px;
  min-height: 51px;
}

div.yukulele {
  font-size: 25px;
  color: orange;
  font-weight: 600;
}

.placeholder-questions-container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.placeholder-questions-image{
  height:150px;
}

.placeholder-questions-label{
  font-size: 14px;
  color: #915EDE;
  text-align: center;
  margin: 10px;
}
.placeholder-questions-label-title{
  font-size: 20px;
  font-weight: bold;
  color: #915EDE;
  text-align: center;
  margin-top: 12px;

}

.placeholder-question-list{
  width: 100%;
  height: 30%;
  background: white;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.placeholder-question-list-line{
  width: 80%;
  height: 12%;
  border-radius: 10px;
  margin: 7px;
  background: #F4EFFF;
}

.rv-xy-plot text{
  color: blue!important;
}


.chart-labels{
  opacity: 0;
  font-size: 9px;
}
.rv-xy-plot__series--label-text{
  color: blue;
  background: pink;
  font-size: 20px;
}

.chart-labels{
  opacity: 0;
  font-size: 9px;

}

.chart-labels-fade{
  opacity: 1;
  font-size: 9px;
  transition: opacity 0.5s linear;

}

.rv-xy-plot__axis__tick__text{
  /* font-size: 35px !important; */

}

.chart-x-axis{
  color: "blue";
  opacity: 1;
  font-size: 35px !important;

}