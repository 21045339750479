#progress-container {
  height: 50px;
  width: 300px;
  margin-top: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-step-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 80px;
}

.progress-step-circle {
  height: 22px;
  width: 22px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border: 1px solid #8A8A8A;
  color: #8A8A8A;
  z-index: 999;
}

.progress-circle-complete {
  background: #4DBD58;
  border: none;
  color: #fff;
}

.progress-step-label {
  margin-top: 10px;
  color: #8A8A8A;
  font-weight: bold;
  font-size: 11px;
}

.progress-step-complete {
  color: #25173D;
}

#progress-line {
  top: 12px;
  position: absolute;
  height: 1px;
  width: 150px;
  background: #8A8A8A;
}


