h5#login-prompt {
  font-weight: 500;
  margin-top: 10px;
  height: 30px;
}

h5.negative {
  color: #ef2b7a;
}

h5.positive {
  color: #6fb8af;
}

.verify-button{
  margin-bottom: 30px;
}