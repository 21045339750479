#new-wheel-container{
  width: 100%;
  height: 95vh;
  border-radius: 8px;
  padding: 40px 20px 20px 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  position: relative;
}

#new-wheel-main-container{
  width: 85vw;
  margin-left: 15vw;
  height: 100vh;
  border-radius: 8px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  display: block;
  z-index: 999;
}

#new-wheel-left-column{
  width: 30%;
  height: 95%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#new-wheel-right-column{
  padding-top: 65px;
  margin-left: 80px;
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}



#new-wheel-wheel-container{
  width: 270px;
  padding: 0px;
  height: 270px;
  position: relative;
  margin-bottom: 20px;
  margin-top: 20px;
}

#new-wheel-svg{
  transform: scale(0.7);
  position: absolute;
  top: -75px;
  left: -77px;
}

#new-wheel-title{
  height: 10%;
  font-size: 29px;
  font-family: "DM Sans";
  font-weight: 900;
}

.new-wheel-minititle{
  font-size: 11px;
  font-family: "DM Sans";
  font-weight: 900;
}

.new-wheel-actiontitle{
  font-size: 19px;
  height: 30px;
  font-family: "DM Sans";
  font-weight: 500;
  border-bottom: 1px solid rgba(151,151,151,0.25);
  margin-bottom: 10px;
}

.new-wheel-actiontext{
  font-size: 14px;
  margin-bottom: 10px;
}


.wheel-color-transition{
  -webkit-transition: .8s ease;
    transition: .8s ease;
}

.new-wheel-template-button{
  font-size: 19px;
  height: 40px;
  font-family: "DM Sans";
  border-radius: 6px;
  font-weight: 500;
  color: #ffffff;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0.65;
}
.new-wheel-template-button:hover{
  opacity: 1;
}

.new-wheel-template-button-selected{
  opacity: 1 ;
}

.new-wheel-create-button{
  font-size: 19px;
  height: 40px;
  width: 100%;
  font-family: "DM Sans";
  border-radius: 6px;
  font-weight: 500;
  color: #ffffff;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(151,151,151,0.4);
  
}

.new-wheel-create-button:hover{
  opacity: 0.8;
}





#back-to-wheels{
  font-size: 14px;
  position: absolute;
  top: 15px;
  left: 25px;
  font-weight: bold;
  cursor: pointer;
}

#new-wheel-buttons{
  margin-top: 20px;
  width: 100%;
}