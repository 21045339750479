#change-plan-container{
  width: 100%;
  height: 84vh;
  z-index: 99999;
  position: relative;
}

.plan-tiles-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: top;
  padding-top: 25px;
  padding-bottom: 40px;
  margin-bottom: 40px;
}

.plan-tile{
  width: 30%;
  border-radius: 20px;
  box-shadow: 0 4px 22px rgb(69 7 119 / 11%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px 15px 30px;
}

#plan-tile-trial {
  height: 430px;
}

#plan-tile-growth {
  height: 650px;
}

#plan-tile-boost {
  height: 800px;
}

.plan-tile-main-content{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.plan-switch-billing-container{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 40px;
  margin-bottom: -30px;
}

.monthly-billing-text{
  color: #979797;
  letter-spacing: -1px;
  font-weight: 500;
  font-size: 24;
  width: 300px;
  text-align: right;
  padding-right: 10px;
}

.annual-billing-text{
  color: #979797;
  font-size: 24;
  letter-spacing: -1px;
  font-weight: 500;
  width: 300px;
  text-align: left;
  padding-left: 10px;

}


.selected-billing-interval{
  color: #371C4E;
}


.billing-switch-bg{
  width: 64px;
  height: 32px;
  background: #fff;
  border-radius: 16px;
  transition: all 0.4s;
  border-width: 2px;
  border-style: solid;
  border-color: #670FF7;
  cursor: pointer;
}
.billing-switch-bg:hover{
  opacity: 0.8;
}

.switch-bg-annual{
  background: #670FF7;
}

.billing-switch-ball{
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background: #670FF7;
  position: relative;
  top: 2px;
  left: 2px;
  transition: all 0.4s;
}

.switch-ball-annual{
  background: #fff;
  left: 35px;
}

.plan-price-container{
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.plan-price-text{
  color: #6FB8AF;
  font-size: 32px;
  font-weight: 500;
  letter-spacing: -1.2px;
  height: 36px;
}

.plan-price-purple{
  color:#670FF7;
}

.plan-price-yellow{
  color:#FEB910;
}

.plan-price-month{
  margin-left: 4px;
  color: #736083;
  font-size: 18px;
  height: 38px;
  letter-spacing: -1px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.plan-name{
  font-weight: 500;
  font-size: 28px;
  color: #371C4E;
  width: 100%;
  margin-top: 20px;
}

.plan-description{
  font-size: 14px;
  color: #736083;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
}

.plan-perk{
  font-size: 14px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #371C4E;
  letter-spacing: -0.4px;
  margin-bottom: 8px;
}

.perk-check-icon{
  max-width: 22px;
  margin-right: 6px;
}

.current-plan-button{
  color: #670FF7;
  border: 1px solid #670FF7;
  font-size: 13px;
  font-weight: bold;
  border-radius: 9px;
  background: #fff;
  width: 70%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
}

.upgradeable-plan-button{
  font-size: 13px;
  font-weight: bold;
  border-radius: 9px;
  color: #fff;
  background: #670FF7;
  width: 70%;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
}
.upgradeable-plan-button:hover{
  opacity: 0.8;
}


.loading-icon-container{
  width: 100%;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-icon{
  max-width: 70px;
}

.overlay-content-container{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.back-to-account{
  color:#670FF7;
  position: absolute;
  top: -35px;
  left: 0px;
  z-index: 99999;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
}

.back-to-account:hover{
  opacity: 0.7;
}

.checkout-overlay-window{
  width: 900px;
  height: 620px;
  background: #fff;/*#F4EEFF;*/
  border-radius: 15px;
  justify-content: center;
  align-items: center;
  z-index: 999;
  display: flex;
  padding-top: 30px;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.checkout-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  --mask: linear-gradient(to bottom, 
  rgba(0,0,0, 1) 0,   rgba(0,0,0, 1) 80%, 
  rgba(0,0,0, 0) 92%, rgba(0,0,0, 0) 0
) 100% 50% / 100% 100% repeat-x;
  -webkit-mask: var(--mask); 
  mask: var(--mask);
}

.checkout-order-summary {
  width: 35%;
  margin-left: 30px;
  height: 400px;
  border-radius: 15px;
  background: #F4EEFF;
}


.back-to-plan-upgrade{
  color:#670FF7;
  position: absolute;
  top: 20px;
  left: 30px;
  z-index: 99999;
  font-weight: bold;
  cursor: pointer;
  font-size: 16px;
}

.back-to-plan-upgrade:hover{
  opacity: 0.7;
}

.perk-icon {
  margin-right: 5px;
}

.perks-container {
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 100%;
  margin-left: -3px;
  margin-top: 20px;

}

#emoji-perk {
  margin-right: 7px;
}