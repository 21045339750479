#create-account-container{

  background: #F4EEFF;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.pattern1{
  position: absolute;
  max-width: 20vw;
  bottom: -30px;
  left: -30px;
  opacity: 0;
  z-index: 0;
  transition: all 0.7s ease-in-out;
}

.pattern1-in{
  bottom: 0px;
  left: 0px;
  opacity: 1 !important;
}



#create-account-main-window{
  background: #FFF;
  width: 85%;
  height: 90%;
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  box-shadow: 2px 11px 45px rgba(10,10,10,0.3);
  z-index: 1;
  position: relative;
}

#left-column{
  background: #8D00FF;
  border-radius: 20px 0px 0px 20px;
  height: 100%;
  width: 34%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 30px;
}

#logo{
  margin-top: 20px;
  margin-left: 30px;
  max-width: 80px;
}

#logo:hover {
  opacity: 0.7;
}

.illustration1{
  display: none;
  margin-top: 13vh;
  margin-left: 6vw;
  width:30vw;
  opacity: 0;
  transition: all 0.7s ease-in-out;
}

.illustration1-in{
  margin-top: 13vh;
  margin-left: 3vw;
  width:30vw;
  /* opacity: 1; HERE SWITCH 1 */
}


#right-column{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}



.pattern2{
  position: absolute;
  bottom: -60px;
  left: -80px;
  max-height: 18vh;
  transition: all 0.7s ease-in-out;
}

.pattern2-in{
  bottom: 0px;
  left: 0px;
}



.pattern3{
  position: absolute;
  bottom: 3vh;
  left: 8vw;
  max-height: 4vh;
  opacity: 0;
  transition: all 1s ease-in-out;
}

.pattern3-in{
  bottom: 3vh;
  left: 4vw;
  opacity: 1;
}

.pattern4{
  position: absolute;
  bottom: -6vh;
  right: -8vw;
  max-height: 18vh;
  border-radius: 0px 0px 20px 0px;
  transition: all 0.7s ease-in-out;
}

.pattern4-in{
  bottom: 0vh;
  right: 0vw;
}

.pattern5{
  position: absolute;
  bottom: 2vh;
  right: 1vw;
  opacity: 0;
  max-height: 13vh;
  border-radius: 0px 0px 20px 0px;
  transition: all 1.2s ease-in-out;
}

.pattern5-in{
  bottom: 0vh;
  right: 0vw;
  opacity: 1;
}

.pattern6{
  position: absolute;
  top: 11vh;
  right: 3vw;
  opacity: 0;
  max-height: 8vh;
  transition: all 1s ease-in-out;
}

.pattern6-in{
  top: 12vh;
  right: 5vw;
  max-height: 10vh;
  opacity: 1;
}

.pattern7{
  position: absolute;
  top: 3vh;
  right: 2vw;
  opacity: 0;
  max-height: 5vh;
  transition: all 1.2s ease-in-out;
}

.pattern7-in{
  top: 6vh;
  right: 3vw;
  opacity: 1;
  max-height: 7vh;
}

.pattern8{
  position: absolute;
  bottom: -6vh;
  right: -8vw;
  max-height: 15vh;
  border-radius: 0px 0px 20px 0px;
  transition: all 0.7s ease-in-out;
}

.pattern8-in{
  bottom: 0vh;
  right: 0vw;
}

.pattern9{
  position: absolute;
  bottom: 6vh;
  right: 4vw;
  opacity: 0;
  max-height: 3vh;
  transition: all 0.6s ease-in-out;
}

.pattern9-in{
  bottom: 6vh;
  right: 3vw;
  opacity: 1;
}

.pattern10{
  position: absolute;
  top: -10vh;
  left: -10vw;
  max-height: 10vh;
  transition: all 0.7s ease-in-out;
  cursor: pointer;
}

.pattern10:hover{
  opacity: 0.8;
  max-height: 9.5vh;
}



.pattern10-in{
  top: 0vh;
  left: 0vw;
}

.form-header{
  font-family: "DM Sans";
  font-weight: bold;
  color: #55289f;
  font-size: 28px;
  margin-bottom: 0px;
  width: 35vw !important;
}

.form-row{
  height: 68px;
  width: 30vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

#form-content{
  margin-top: 20px;
  margin-left: 5vw;
  opacity: 0;
  display: flex;
  flex-direction: column;
  transition: opacity 0.8s ease-in-out;
}

.opacity-in{
  opacity: 1 !important;
}

.form-input-label{
  font-weight: bold;
  color: #55289f;
  font-size: 11px;
  margin-bottom: 1px;
}

.form-text-input-container{
  width: 49%;
}

.text-input{
  height: 43px !important;
  padding-right: 5px;
  color: #523e77;

}

.full-length{
  width: 100%;
}

.form-select{
  border: 2px solid #55289f;
  height: 43px;
  width: 100%;
  font-size: 16px;
  color: #523e77;
  background: #fff;
}

.form-select-placeholder{
  color: #aeaeae;
}

.form-button{
  margin-top: 0px;
  height: 43px;
  width: 100%;
  background: #8D00FF;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-size: 19px;
  font-family: "DM Sans";
  font-weight: bold;
  cursor: pointer;
  box-shadow: 0px 5px 16px 0px #0000001B;
  transition: 0.1s;
}

.form-button:hover{
  height: 43px;
  width: 100%;
  opacity: 0.7;
}

.form-button:active{
  height: 41px;
  width: 98%;
  font-size: 18px;
  box-shadow: 0px 2px 2px 0px #0000001B;

}

.center-container{
  width: 30vw;
  display:flex;
  flex-direction: column;
  align-items: center;
}
.already-account{
  text-align: center;
  color: #AAA4B4;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
}

.underline{
  margin-bottom: 10px;
  width: 25%;
  text-align: center;
  border-top: 1px solid #AAA4B4;
}

.mandala{
  position: absolute;
  margin: none;
  width: 40vw;
  right: -50vw;
  top: -50vw;
  transition: all 1s ease-out;
  z-index: 3;
  opacity: 0;
}


.mandala-positioned{
  width: 20vw;
  right: -10vw;
  top: -10vw;
  opacity: 1;
}

.checkbox-container{
  justify-content: flex-start;
  position: relative;
}

.TC-checkbox{
  border: 2px solid #55289f;
  border-radius: 4px;
  width: 22px;
  height: 22px;
  margin-right: 10px;
  transition: all 0.3s linear;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TC-checkbox:hover{
  background: #F4EEFF;
  border-radius: 6px;
  margin-right: 10px;
}

.check{
  width: 0px;
  height: 0px;
  background: #55289f; 
  border-radius: 3px;
  transition: all 0.3s linear;
}

.checked{
  width: 13px;
  height: 13px;
}

.tag-container{
  margin-top: 0px;
  width: 130%;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.tag{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  padding: 0px 12px 0px 12px;
  margin: 4px 6px 4px 0px;
  border: 1px solid #55289f;
  border-radius: 8px;
  font-size: 12px;
  font-weight: bold;
  opacity: 0.4;
  color: #55289f;
  cursor: pointer;
  transition: all 0.3s linear;
  user-select: none;
}

.tag-selected{
  opacity: 1;
  color: #fff;
  background: #55289f;
}

.form-row-tags{
  height: auto;
  margin-top: 5px;
}

#TC {
  color: #55289f;
  font-weight: bold;
  text-decoration: underline;
  cursor: pointer;
}

#back-button{
  font-weight: bold;
  color: #fff;
  position: absolute;
  top: 15px;
  left: 20px;
  pointer-events: none;

}


#back-button:hover{
  top: 199px;
}

.error-prompt{
  position: absolute;
  bottom: 40px;
  margin-top: 10px;
  height: 20px;
  font-weight: bold;
  font-size: 14px;
  color: #f97089;
}

#complete-image{
  max-width: 24vh;
  margin-bottom: 10px;
}

.form-text{
  color: #55289f;
  text-align: center;
  line-height: 22px;
  font-size: 15px;
}

.form-row-short{
  height: 50px;
}

.form-row-button{
  height: 80px;
  margin-top: -10px;
  margin-bottom: 10px;
  align-items: flex-end;

}


.form-header-complete{
  width: 30vw !important;
  white-space: nowrap;
  text-align: center;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
}

#send-confirmation-again{
  font-weight: bold;
  color: #55289f;
  text-decoration: underline;
}

.form-error-prompt{
  font-size: 12px;
  color: #ef2b7a;
  height: 30px;
  font-weight: bold;
}

.eye-password{
  position: absolute;
  right: 10px;
  top: 26px;
  max-width: 20px;
  opacity: 0.5;
  cursor: pointer;
}

.eye-password:hover{
  opacity: 1;
}

.form-text-input-container{
  position: relative;
}

.already-account{
  font-weight: normal !important;
  font-size: 12px !important;
}

#weely-logo-container {
  width: 100%;
}

#sidebar-header {
  font-size: 24px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  padding: 10px 25px;
  margin-top: 15px;
}

#sidebar-perks-container{
  display: flex;
  margin-top: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#sidebar-perks-header {
  color: #fff;
  font-weight: bold;
  font-size: 10px;
  margin-bottom: 6px;
}

.sidebar-perk {
  background-color: black;
  color: #fff;
  font-size: 13px;
  font-weight: bold;
  margin-top: 14px;
  padding: 6px 15px;
  border-radius: 20px;
}

.sidebar-perk-1 {
  background-color: #670FF7;
  transform: rotate(6deg);
}

.sidebar-perk-2 {
  background-color: #CB4FFF;
  transform: rotate(-6deg);
}

.sidebar-perk-3 {
  background-color: #EF2B7A;
  transform: rotate(3deg);
}

.sidebar-perk-4 {
  background-color: #6FB8AF;
  transform: rotate(-2deg);
}

.sidebar-perk-5 {
  background-color: #FEB910;
  transform: rotate(3deg);
}

#sidebar-testimonial-container{
  margin-top: 30px;
  background-color: #00000042;
  border-radius: 10px;
  width: 87%;
  color: #fff;
  padding: 15px 15px;
}

#sidebar-testimonial-text {
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  font-weight: light;
}

#sidebar-testimonial-witness{
  display: flex;
  align-items: center;
  margin-top: 6px;
  font-size: 12px;
  gap: 10px;
}

#sidebar-witness-photo {
  max-width: 50px;
  border-radius: 50%;
}

#sidebar-witness-title {
  font-size: 10px;
  margin-top: 4px;
  font-weight: bold;
}

#welcome-club-container {
  margin-top: 10px;
}

#welcome-club-text {
  font-size: 10px;
  color: #fff;
  font-weight: bold;
  margin-bottom: 10px;
}

#welcome-logos-container {
  display: flex;
}

.welcome-logo {
  max-height: 35px;
}


.email-sent-to {
  padding: 40px 30px;
  background: #F4EEFF;
  border-radius: 3px;
  margin-bottom: 9px;
}

.form-row-text-email {
  font-size: 17px;
}