div.admin-nav {
  background-color: #f4eeff;
  height: 100vh;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 15vw;
  overflow-x: hidden;
  padding-top: 20px;
}

div.nav-wrapper {
  position: relative;
  height: 100%;
}

img.nav-logo {
  height: 30px;
  display: block;
  margin: 50px auto 0px auto;
}

ul.nav-items {
  padding-left: 0px;
  margin-top: 15vh;
}

ul.nav-items li {
  list-style: none;
  text-align: center;
  padding: 8px 0px;
  font-size: 18px;
  width: 75%;
  margin: 10px auto;
  border-radius: 14px;
  color: #8d00ff !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

ul.nav-items li a {
  text-decoration: none;
  color: #8d00ff;

}

ul.nav-items li a:visited {
  color: #8d00ff;
}

ul.nav-items li a:hover {
  font-weight: 500;
}

ul.nav-items li#active {
  color: white;
  background-color: #8d00ff;
  font-weight: 500;
}

ul.nav-items li#active a {
  text-decoration: none;
  color: white;
  background-color: #8d00ff;
  font-weight: 500;
}
li#support:hover {
  font-weight: 500;
  cursor: pointer;
}

div#support-info {
  background-color: white;
  width: 85%;
  margin: 3vh auto;
  padding: 5px;

  padding-left: 15px;
  border-radius: 5px;
  position: relative;
}

div#support-info.hidden {
  visibility: hidden;
}

div#support-info h6 {
  font-size: 12px;
  color: #8d00ff;
  font-weight: 500;
}

div#support-info button {
  background-color: white;
  border: none;
  color: #8d00ff;
  float: right;
}

div#support-info button:hover {
  cursor: pointer;
}

h6#logout {
  text-align: center;
  margin-top: 10px;
  font-size: 15px;
  color: #640aad;
  opacity: 0.3;
}

h6#logout:hover {
  cursor: pointer;
  opacity: 1;
}

.navbar-icon{
  max-height: 16px;
  margin-right: 8px;
  position: relative;
  top: 1px;
}

.navbar-close-support{
  font-size: 10px;
  position: absolute;
  top: 3px;
  right: 5px;
}

.support-icon{
  max-width: 12px;
  max-height: 12px;
  margin-top: 2px;
  margin-right: 7px;
}

.support-row{
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}
.nav-languages-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: #b562f9;
  cursor: pointer !important;
}

.nav-language {
  font-weight: bold;
  font-size: 12px;
}

.nav-language:hover {
  opacity: 0.5;
}

.language-selected {
  text-decoration: underline;
  color: #8d00ff;
}

.nav-footer {
  position: absolute;
  bottom: 90px;
  width: 100%;
}