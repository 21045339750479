.actions-container{
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.actions-row{
    width: 100%;
    height: 9vh;
    display:flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 10px;

}

.actions-logo{
    width: 20%;
    height: 100%;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.actions-value{
    background: #B689FF;
    color: white;
    border-radius: 5px;
    margin-left: 5px;
    font-size: 15px;
    font-weight: bold;
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.actions-logo-img{
    height: 100%;
}

.placeholder-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40%;
}

.placeholder-image{
    max-height: 120px;
}

.placeholder-label{
    font-size: 13px;
    color: #915EDE;
    text-align: center;
    margin-top: 10px;
}
.placeholder-label-title{
    font-size: 20px;
    font-weight: bold;
    color: #915EDE;
    text-align: center;
    margin-top: 12px;

}