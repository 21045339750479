div#qr-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 60px;
  width: 500px;
  height: 500px;
  position: relative;
  border-radius: 14px;
  text-align: center;
  padding: 30px;
  border: 3px dashed #F4EEFF;
}

.qr-export-container{
  border-radius: 100%;
}

.qr-generator-content{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-items: start;
}

.qr-generator-column{
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-direction: column;
}

.download-qr-button{
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-weight: bold;
  width: 70%;
  height: 27px;
  background: #8D00FF;
  border-radius: 7px;
  cursor: pointer;
  font-size: 15px;
}

.download-qr-button:hover{
  opacity: 0.8;
}

.qr-background-container{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top:0px;
  left: 0px;
  width: 440px;
  height: 440px;
  border-radius: 50%;
  overflow: hidden;
}

#qr-background-sub-container{
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top:0px;
  left: 0px;
  transform: scale(0.4);
}

.qr-code{
  z-index: 990;
  width: 120px;
  height: 120px;
}


#qr-circle-mask{
  position: absolute;
  width: 820px;
  height: 820px;
  border-radius: 50%;
  border: 1px solid green;
  overflow: hidden;
  z-index: 99;
}

#qr-bg{
  z-index: 4;
}

#topkek{
  transform: scale(1.4);
  transform-origin: top left;
  border-radius: 50%;
}

#qr-code-container{
  /* border: 1px solid black; */
  position: absolute;
  top: 0px;
  left: -1px;
  width: 494px;
  height: 512px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.step-icon{
  max-width: 30px;
  position: absolute;
  opacity: 0.6;
}

.step-icon-1{
  top: 248px;
  left: 109px;
}

.step-icon-2{
  top: 347px;
  left: 233px;
}

.step-icon-3{
  top: 246px;
  right: 108px;
}

.step-text{
  position: absolute;
  opacity: 0.9;
  width: 80px;
  height: 40px;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
}

.step-text-1{
  top: 278px;
  left: 82px;
}

.step-text-2{
  top: 378px;
  left: 208px;
}

.step-text-3{
  top: 278px;
  right: 82px;
}

.qr-weely-logo{
  position: absolute;
  top: 120px;
  left: 160px;
  max-width: 160px;
  z-index: 9;
  fill: pink;
}

.qr-logo-container{
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  z-index: 999;
  top: 115px;
  left: 140px;
  transform-origin: top left;
}

.qr-logo-weely{

  /* width: 38%;
  height: 9.34%; */
  padding-top: 0px;
  padding-bottom: 0px;

}

.qr-options-container{
  width: 280px;
  height: 500px;
  right: 0px;
  padding: 12px 4px;
  background: #F4EEFF;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.qr-options-wrapper {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.qr-options-row{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 35px;
  width: 100%;
  padding: 3px 9px 3px 9px;
}

.qr-options-title{
  font-family: "DM Sans";
  font-weight: 500;
  color: #8D00FF;
  width: 100%;
  margin-bottom: 10px;
  font-size: 18px;
  position: relative;
}

.qr-options-underline{
  border-bottom: 1px solid #d2abf2;
  margin-top: 10px;
  width: 88%;
  margin-bottom: 10px;
}


.qr-color-input-label{
  color: #8D00FF;
  font-size: 14px;
}

.qr-language{
  width: 85px;
  height: 27px;
  border: 1px solid #8D00FF;
  color: #777777;
  margin-top: 13px;
}

.qr-pattern-overlay{
  width: 850px;
  height: 850px;
  position: absolute;
  border: 1px solid;
  border-radius: 50%;
}

.qr-wheel-logo{
  position: absolute;
  left: 0; 
  right: 0; 
  top: 120px;
  margin-left: auto; 
  margin-right: auto; 
  max-width: 170px;
  border: 1px solid black;
  max-height: 100px;
  z-index: 99999;
  opacity: 0;
}

.qr-button-row{
  display: flex;
  width: 100%;
  height: 60px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.save-qr-button{
  display: flex;
  justify-content: center;
  align-items: center;
  background: #8D00FF;
  color: #fff;
  padding: 5px 30px !important;
  min-width: 110px;
  margin: 12px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
}

.save-qr-button:hover{
  opacity: 0.8;
}


.save-qr-button-disabled{
  opacity: 0.6;
  cursor: default;
}

.save-qr-button-disabled:hover{
  opacity: 0.6;
}

.download-options-container {
  margin-top: -20px;
  width: 500px;
}

.download-options-header {
  font-family: "DM Sans";
  font-weight: 500;
  color: #8D00FF;
  font-size: 18px;
  margin-bottom: 10px;
}

.download-options-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.download-option-container{
  border: 1px solid #8D00FF;
  border-radius: 7px;
  width: 242px;
  height: 242px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: left;
  padding: 25px;
  gap: 8px;
}

.download-option-header {
  font-family: "DM Sans";
  font-weight: 500;
  color: #8D00FF;
}

.download-option-text {
  margin-top: -5px;
  font-family: "DM Sans";
  font-weight: 400;
  color: #8D00FF;
  font-size: 14px;
  text-align: left;
}

.download-option-icon {
  height: 25px;
  width: 25px;
}

.save-qr-reset{
  background-color: #EF2B7A;
}