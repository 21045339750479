div.mailing-list-container {
  margin-top: 20px;
  border: 1.5px solid #670FF7;
  border-radius: 5px;
  width: 700px;
  padding-bottom: 30px;
  padding-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}

.mailing-list-icon {
  max-width: 150px;
  margin-bottom: 20px;
}

div.mailing-list-header {
  font-weight: bold;
  font-size: 20px;
  color: #55289F;
  margin-bottom: 8px;
}

.mailing-list-text {
  text-align: center;
  width: 350px;
  color: #55289F;
}

.mailing-list-button {
  background: #8D19FF;
  width: 240px;
  height: 35px;
  margin-top: 20px;
  border-radius: 10px;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.mailing-list-button:hover {
  opacity: 0.6;
}