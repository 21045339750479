ul#section-selector {
  padding: 0px;
  margin-top: 5vh;
}

ul#section-selector li {
  display: inline-block;
  font-size: 18px;
  color: #670ff7;
  font-weight: 500;
  margin-right: 30px;
  opacity: 0.5;
}

ul#section-selector li.selected {
  border-bottom: 2px solid #670ff7;
  opacity: 1;
}

ul#section-selector li:hover {
  cursor: pointer;
}
