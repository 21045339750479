.lock-container {
  position: relative;
  display: flex;
  flex-direction: horizontal;
  opacity: 1 !important;
}

.lock-icon {
  max-height: 16px;
  cursor:help;
  margin-left: 10px;
}

.tooltip-text {
  position: absolute;
  background: #fff !important;
  border: 1px solid #8D00FF;
  font-size: 14px;
  border-radius: 5px;
  padding: 8px 15px;
  width: 170px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 30px;
  z-index: 99;
}