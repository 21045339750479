.profile-info-container{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 600px;
  color: #670FF7;
}

#edit-profile-icon{
  position: absolute;
  max-width: 40px;
  top: 0px;
  right: -50px;
  cursor: pointer;
}
#edit-profile-icon:hover{
  max-width: 41px;
  opacity: 0.8;
}

.profile-info-row{
  width: 85%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  height: 70px;
}

.account-header{
  margin-bottom: 15px;
}

.profile-info-input-label{
  width: 45%;
  margin-right: 20px;
}

.profile-info-label{
  font-size: 14px;
  font-weight: bold;
}


.profile-info-input{
  height: 10px !important;
}

.button-account{
  background: #8D00FF;
  color: #fff;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 45%;
  height: 35px;
  margin-right: 20px;
  margin-top: 10px;
  cursor: pointer;
}

.button-account-disabled {
  opacity: 0.5;
  cursor: default;
  pointer-events: none;
}

.button

.button-account:hover{
  opacity: 0.8;
}

.cancel-changes{
  background: #EF2B7A !important;
}

.profile-plan-container{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  color: #670FF7;
  margin-top: 0px;
}

.account-plan-tiles{
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
  height: 500px;

}

.account-plan-column{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 48%;
  margin-right: 15px;
  height: 100%;
}

.account-plan-details{
  border: 2px solid #670FF7;
  border-radius: 10px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.account-plan-payment{
  border: 2px solid #670FF7;
  border-radius: 10px;
  height: 225px;
  width: 100%; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.account-plan-addOns{
  border: 2px solid #670FF7;
  border-radius: 10px;
  height: 500px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 20px;
}


.account-plan-buttons-row{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.button-account-plan{
  background: #8D00FF;
  color: #fff;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 35%;
  height: 35px;
  cursor: pointer;
  margin-right: 20px;
  margin-left: 20px;
}

.button-account-plan:hover{
  opacity: 0.8;
}

#change-plan{
  width: 100%;
  height: 100%;
}

.account-overlay{
  width: 100%;
  height: 100%;
}

.account-plan-settings-icon{
  max-width: 24px;
  margin-right: 8px;
}

.account-plan-settings-header{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  padding: 14px 0px 0px 14px;
}

.spin-gauge-bg{
  height: 150px;
  width: 150px;
  border-radius: 50%;
  border: 1px solid #670FF7;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spin-gauge-middle{
  height: 110px;
  width: 110px;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #670FF7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

.account-plan-details-content{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 15px;
}

.account-plan-details-row{
  font-size: 14px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2px;
}

.account-plan-details-text-left{
  width: 50%;
  text-align: right;
  padding-right: 10px;
  color: #670FF7;
  opacity: 0.5;
}

.account-plan-details-text-right{
  width: 50%;
  text-align: left;
  padding-left: 10px;
  color: #670FF7;
  font-weight: bold;

}

.cancel-plan-container{
  width: 100%;
}

.cancel-plan-header{
  margin: 0px 0px;
  font-size: 25px;
  color: #670FF7;
}

.cancel-plan-text{
  margin-top: 10px;
  font-size: 13px;
  color: rgba(0,0,0,0.81);
}

.account-plan-help-icon{
  position: absolute;
  right: 10px;
  top: 8px;
  max-width: 20px;
  cursor: pointer;
}

.account-plan-help-icon:hover{
  opacity: 0.8;
  max-width: 22px;
  right: 9px;
  top: 7px;
}

.account-plan-addOns-select-row{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 86%;
}

.account-plan-addOns-wheels{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #FEB910;
}

.account-plan-addOns-spins{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #6FB8AF;
}

.account-plan-addOns-label{
  font-weight: bold;
  display: flex;
  align-items: center;
  letter-spacing: -0.46px;
  height: 25px;
}

.account-plan-addOns-extra-icon{
  max-width: 25px;
  margin-right: 4px;
  margin-left: -3px;
}

.account-plan-addOns-extra-icon-spins{
  max-width: 19px;
  margin-right: 4px;
}

.add-on-selector {
  font-size: 16px;
  padding: 0px 10px 0px 10px;
  border-radius: 12px;
  width: 14vw;
  font-family: "DM Sans", sans-serif;
  border: 2px solid #670ff7;
  appearance: none;
  color: #670ff7;
  font-weight: 500;
  margin-top: 3px;
  height: 40px;
  letter-spacing: -0.19px;
  cursor: pointer;
}

.add-on-selector:focus {
  outline: none;
}

.add-on-select-wrapper{
  width: 14vw;
  height: 42px;
  position: relative;
}

.selector-custom-arrow-icon{
  position: absolute;
  top: 16px;
  right: 14px;
  max-width: 11px;
  pointer-events: none;
  user-select: none; 
}

.wheel-price-yellow{
  color: #FEB910;
}

.spin-price-green{
  color: #6FB8AF;
}

.account-plan-addOns-price-row{
  margin-top: 15px;
  font-size: 20px;
  letter-spacing: -0.5px;
}

.account-plan-button-line{
  margin-top: 15px;
  width: 20%;
  height: 1px;
  margin-bottom: 12px;
  border-top: 1px solid rgba(57, 49, 75, 0.2);
}

.addOns-button{
  background: #8D00FF;
  color: #fff;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 45%;
  height: 35px;
  cursor: pointer;
  margin-bottom: 6px;
}

.addOns-button:hover{
  opacity: 0.8;
}

.addOns-button-disabled{
  box-sizing: border-box;
  background: #fff;
  color: #8D00FF;
  opacity: 0.2;
  border: 2px solid #8D00FF;
  display: flex;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  width: 45%;
  height: 35px;
  pointer-events: none;
}

.account-plan-addOns-content{
  margin-top: 22px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.account-plan-payment-content{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
}

.payment-details-container{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 85px;
}
.payment-details-left-column{
  display: flex;
  flex-direction: column;
  justify-content:right;
  align-items: center;
  width: 180px;
}

.payment-details-card{
  max-width: 120px;
  margin: 0px 20px 10px 40px;
}

.payment-details-card-number{
  font-weight: bold;
  font-size: 20px;
  letter-spacing: -0.45px;
  text-align: right;
  width: 100%;
}

.payment-details-card-expiration{
  font-weight: normal;
  font-size: 13px;
  letter-spacing: -0.45px;
  text-align: right;
  width: 100%;

}

.payment-details-card-undefined{
  font-weight: bold;
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 120px;
  height: 80px;
  border: 1px solid #670FF7;
  border-radius: 5px;
  margin: 0px 20px 0px 40px;
}

.payment-details-paypal{
  max-width: 140px;
}


.account-plan-receipts-container{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: baseline;
  width: calc(96% + 15px);
  height: 240px;
  margin-top: 15px;
  border: 2px solid #670FF7;
  border-radius: 10px;
}

.account-plan-receipts{
  width: 100%;
  max-width: 900px;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-x: scroll;
  scroll-behavior: smooth;
  overflow-y: hidden;
  mask:radial-gradient(circle, #fff 95%, transparent 100%);
}

.account-plan-receipts-empty{
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.receipt-tile{
  width: 180px;
  min-width: 180px;
  height: 140px;
  border: 1px solid #670FF7;
  background: white;
  border-radius: 12px;
  margin: 0px 20px 0px 20px;
  box-shadow: 2px 2px 8px 2px rgba(103,15,247,0.10);
  transition: all 0.1s linear;
  cursor: pointer;
  box-sizing: border-box;
}

.receipt-tile:hover{
  transform: scale(1.02)
}

.receipt-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
  font-weight: bold;
  font-size: 15px;
  border-bottom: 1px dashed #670FF7;
}

.receipt-product-name{
  width: 100%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
  
.account-plan-details-line{
  border-bottom:1px solid #F4EEFF;
  width: 50%;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 1px;

}

.plan-details-add-ons2{
  align-items: baseline;
}

.extra-wheels-text{
  color: #FEB910;
}

.extra-spins-text{
  color: #6FB8AF;
}

.plan-details-max-add-on{
  display: inline;
}