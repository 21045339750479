div#holder {
}

div.admin-body h4 {
  font-weight: 500;
  margin: 10px 0px;
  color: #670ff7;
}

div.admin-body button {
  background-color: #8d00ff;
  border-radius: 14px;
  height: 5vh;
  width: 5vh;
  font-size: 21px;
  font-weight: 500;
  color: white;
  border: none;
  float: right;
}

div.admin-body button:hover {
  cursor: pointer;
}

div.admin-body button:focus {
  outline: none;
}

div.admin-body button:active {
  transform: scale(0.98);
}

div#card-holder {
  margin-top: 60px;
}

div.card-container{
  width: 100%;
  height: 100%;
}

div.wheel-card {
  border: 1px solid #670ff7;
  margin: 15px;
  border-radius: 14px;
  box-sizing: border-box;
  padding: 2vh;
  width: 30%;
  box-shadow: 6px 3px 10px rgba(0, 0, 0, 0.1);
  min-width: 30%;
  display: inline-block;
}

.card-locked {
  cursor: pointer;
  pointer-events: none;
  opacity: 0.4;
  z-index: 999;
}

.wheel-card:nth-child(3n) {
  page-break-after: always;
  break-after: always; 
}


h5.card-link {
  margin: 0px;
  text-align: right;
  font-size: 14px;
  font-weight: 600;
  color: #8d00ff;
}

div.wheel-card div.card-top {
  display: flex;
}

div.top-left {
  flex: 7;
}

div.top-right {
  flex: 3;
}

h5.card-link a {
  text-decoration: none;
}

h5.card-link a:visited {
  text-decoration: none;
  color: #8d00ff;
}

div.top-left h4.wheeltype {
  color: #feb910;
  font-size: 11px;
  font-weight: 600;
}

div.top-left h3.wheel-name {
  color: #670ff7;
  font-size: 22px;
  line-height: 25px;
  font-weight: 600;
  border-bottom: 1px solid #310d6b23;
  margin-bottom: 15px;
  padding-bottom: 15px;
  width: 80%;
}

h4.period-title {
  font-size: 15px;
  color: #670ff7;
  font-weight: 600;
}

div.card-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

div.card-bottom div.bottom-section {
  border: 1px solid #670ff7;
  height: 100px;
  border-radius: 14px;
  margin: 0px;
  display: flex;
  flex-direction: column;
  width: 47%;
  padding: 7px;
  box-sizing: border-box;
}

div.card-bottom div.bottom-section h6 {
  margin: 0px;
  flex: 30;
}

div.bottom-section h6#top {
  text-align: right;
  font-size: 13px;
}

div.bottom-section h6.up {
  color: #6fb8af;
}

div.bottom-section h6.down {
  color: #ef2b7a;
}

div.card-bottom div.bottom-section h5 {
  color: #670ff7;
  font-size: 22px;
  margin: 0px;
  flex: 40;
  text-align: center;
}

div.bottom-section h6#bottom {
  text-align: center;
  font-size: 12px;
  color: #670ff7;
  font-weight: 500;
}

.header-container{
  display: flex;
  flex-direction: row;
}

.external-link{
  margin-left: 20px;
  margin-top: 14px;
  max-width: 27px;
  max-height: 27px;

  cursor: pointer;
}

.locked-banner {
  width: 550px;
  border: 1px solid #ef2b7a;
  color: #ef2b7a;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: -50px;
}

#new-wheel-button:hover{
  opacity: 0.7;

}