div#holder {
  display: flex;
  flex-direction: row;
  margin: 0px;
  font-family: "DM Sans", sans-serif;
}

div#login-navbar {
  background-color: #f4eeff;
  height: 100vh;
  flex: 30;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

img#login-logo {
  height: 30px;
  width: 110px;
  margin: 40px;
  display: block;
}

div#login-holder {
  width: 70%;
  height: 70vh;
  margin: 25vh auto 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

div#login-navbar h2 {
  font-size: 30px;
  color: #5f29b7;
  letter-spacing: -0.93;
  margin-bottom: 10px;
}

input.text-input {
  height: 46px;
  width: 100%;
  border-radius: 4px;
  border: 2px solid #55289f;
  padding-left: 13px;
  font-size: 18px;
  font-family: "DM Sans", sans-serif;
  box-sizing: border-box;
}
input.text-input::placeholder {
  opacity: 0.5;
}

div#login-holder label {
  color: #421788;
  font-size: 11px;
  font-weight: 700;
}

button.primary-button {
  background-color: #cb4fff;
  color: white;
  border-radius: 12px;
  height: 46px;
  font-size: 19px;
  font-weight: 700;
  font-family: "DM Sans", sans-serif;
  border: none;
  box-shadow: 0px 5px 16px 0px rgba(0, 0, 0, 0.11);
}

button.primary-button:hover {
  cursor: pointer;
}

button.primary-button:active {
  transform: scale(0.99);
  box-shadow: none;
}

button.primary-button:focus {
  outline: 0;
}

button#login {
  width: 100%;
  margin-top: 20px;
}

h5.sublogin-link {
  color: rgba(107, 96, 124, 57);
  font-size: 13px;
  margin-top: 20px;
  opacity: 0.8;
  text-align: center;
  margin-bottom: 10px;
}

h5.forgot-password {
  color: rgba(107, 96, 124, 57);
  font-size: 11px;
  opacity: 0.8;
  text-align: center;
  margin-top: 4px;
  text-align: right;
}

h5.create-account {
  color: rgba(107, 96, 124, 57);
  font-size: 13px;
  text-decoration: underline;
  margin-top: 20px;
  opacity: 0.8;
  text-align: center;
}


div#page-holder {
  height: 100vh;
  background-color: white;
  flex: 70;
}

div#page-holder img#login-print {
  height: 60vh;
  display: block;
  margin: 20vh auto;
}



div#login-prompt {
  font-weight: 500;
  font-size: 14px;
  margin-top: 7px;
  height: 20px;
  color: #ef2b7a !important;
  text-align: center;
}

.send-verification-again {
  margin-top: 20px;
  font-weight: bold;
  font-size: 13px;
  height: 20px;
  text-align: center;
  color: rgba(107, 96, 124, 57);

}

#send-again-button{
  color: #cb4fff;
  text-decoration: underline;
  cursor: pointer;

}