* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
}

:root {
  --multiplier: 4.7; /* Set the multiplier value */
}

.wheel {
  margin-bottom: 23px;
  position: auto;
  width: calc(50px * var(--multiplier));
  height: calc(50px * var(--multiplier));
  font-family: "DM Sans", sans-serif;
  
}

.mainbox {
  position: relative;
  width: calc(50px * var(--multiplier));
  height: calc(50px * var(--multiplier));
  display: inline-block;
}

.mainbox:after {
  position: absolute;
  content: "";
  width: calc(3.2px * var(--multiplier));
  height: calc(3.2px * var(--multiplier));
  background-size: calc(3.2px * var(--multiplier));
  right: calc(-3px * var(--multiplier));
  top: 50%;
  transform: translateY(-50%);
  display: inline-block;
}

.box {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 50%;
  border: calc(0.7px * var(--multiplier)) solid #fff;
  overflow: hidden;
  transition: all ease 0s;
}

span {
  width: 50%;
  height: 50%;
  font-family: "DMSans";
  font-size: 2px;
  display: inline-block;
  position: absolute;
}
.span1 {
  clip-path: polygon(0 92%, 100% 51%, 0 8%);
  top: calc(12px * var(--multiplier));
  left: 0;
}
.span2 {
  clip-path: polygon(100% 92%, 0 51%, 100% 8%);
  background-color: #e91e1e;
  top: calc(12px * var(--multiplier));
  right: 0;
}
.span3 {
  clip-path: polygon(50% 0%, 8% 100%, 92% 100%);
  bottom: 0;
  left: calc(12px * var(--multiplier));
}
.span4 {
  clip-path: polygon(50.5% 100%, 92% 0, 8% 0);
  top: 0;
  left: calc(12px * var(--multiplier));
}
.span5 {
  clip-path: polygon(0 92%, 100% 50%, 0 8%);
  top: calc(12px * var(--multiplier));
  left: 0;
}
.span6 {
  clip-path: polygon(100% 92%, 0 50.5%, 100% 8%);
  top: calc(12px * var(--multiplier));
  right: 0;
}
.span7 {
  clip-path: polygon(50.5% 0%, 8% 100%, 92% 100%);
  bottom: 0;
  left: calc(12px * var(--multiplier));
}
.span8 {
  clip-path: polygon(50% 100%, 92% 0, 8% 0);
  top: 0;
  left: calc(12px * var(--multiplier));
}

/*Little bit adjust the value*/
.box1 .span3 b {
  transform: translate(-50%, -50%) rotate(-270deg);
}
.span1 b,
.span5 b,
.span4 b {
  transform: translate(-50%, -50%) rotate(185deg);
}
.span7 b {
  transform: translate(-50%, -50%) rotate(90deg);
}
.span4 b,
.span8 b {
  transform: translate(-50%, -50%) rotate(-85deg);
}

.box2 {
  width: 100%;
  height: 100%;
  transform: rotate(-135deg);
}
span b {
  line-height: calc(6.5px * var(--multiplier));
  font-size: calc(1.4px * var(--multiplier));
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(5.5px * var(--multiplier));
  height: calc(5.5px * var(--multiplier));
  border-radius: 50%;
  border: calc(0.4px * var(--multiplier)) solid #fff;

  color: #fff;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
}
.prizePicker {
  position: absolute;
  top: calc(25px * var(--multiplier));
  left: calc(49px * var(--multiplier));
  transform: translate(-50%, -50%);
  width: calc(10px * var(--multiplier));
  height: calc(10px * var(--multiplier));
  color: #fff;
  z-index: 4;
}

.mainbox.animate:after {
  animation: animateArrow 0.7s ease infinite;
}
#circleBack {
  position: absolute;
  width: calc(53px * var(--multiplier));
  height: calc(53px * var(--multiplier));
  left: calc(-1.5px * var(--multiplier));
  top: calc(-1.5px * var(--multiplier));
  border-radius: 50%;
  border: calc(2.2px * var(--multiplier)) solid #670ff7;
  box-shadow: 0 5px 22px 0 rgba(0, 0, 0, 0.2);
  
  z-index: -1;
}

.prizeLabel {
  width: calc(17px * var(--multiplier));
  height: calc(7px * var(--multiplier));
  z-index: 10;
  font-size: calc(1.8px * var(--multiplier));
  font-family: "DM Sans", sans-serif;
  line-height: calc(1.9px * var(--multiplier));
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.triangle {
  width: 0;
  height: 0;
  border-left: calc(1px * var(--multiplier)) solid transparent;
  border-right: calc(1px * var(--multiplier)) solid transparent;
  border-bottom: calc(1px * var(--multiplier)) solid white;
  z-index: 10;
}
.circle {
  width: calc(0.6px * var(--multiplier));
  height: calc(0.6px * var(--multiplier));
  border-radius: 50%;
  background-color: white;
  z-index: 9;
}

#prizeLabel1 {
  position: absolute;
  left: calc(16.4px * var(--multiplier));
  top: calc(5.9px * var(--multiplier));
  transform: rotate(-90deg);
}
#prizeLabel2 {
  position: absolute;
  left: calc(16.4px * var(--multiplier));
  top: calc(37px * var(--multiplier));
  transform: rotate(90deg);
}
#prizeLabel3 {
  position: absolute;
  left: calc(0.8px * var(--multiplier));
  top: calc(21.6px * var(--multiplier));
  transform: rotate(180deg);
}
#prizeLabel4 {
  position: absolute;
  left: calc(32px * var(--multiplier));
  top: calc(21.6px * var(--multiplier));
}
#prizeLabel5 {
  position: absolute;
  left: calc(27.5px * var(--multiplier));
  top: calc(32.5px * var(--multiplier));
  transform: rotate(45deg);
}
#prizeLabel6 {
  position: absolute;
  left: calc(27.5px * var(--multiplier));
  top: calc(10.5px * var(--multiplier));
  transform: rotate(-45deg);
}
#prizeLabel7 {
  position: absolute;
  left: calc(5.4px * var(--multiplier));
  top: calc(10.7px * var(--multiplier));
  transform: rotate(-135deg);
}
#prizeLabel8 {
  position: absolute;
  left: calc(5.5px * var(--multiplier));
  top: calc(32.6px * var(--multiplier));
  transform: rotate(-225deg);
}

#triangle1 {
  position: absolute;
  left: calc(33.6px * var(--multiplier));
  top: calc(1px * var(--multiplier));
  transform: rotate(22.5deg);
}

#triangle2 {
  position: absolute;
  left: calc(47.7px * var(--multiplier));
  top: calc(15.1px * var(--multiplier));
  transform: rotate(67.5deg);
}

#triangle3 {
  position: absolute;
  left: calc(47.4px * var(--multiplier));
  top: calc(34.3px * var(--multiplier));
  transform: rotate(112.5deg);
}

#triangle4 {
  position: absolute;
  left: calc(33.6px * var(--multiplier));
  top: calc(48px * var(--multiplier));
  transform: rotate(157.5deg);
}
#triangle5 {
  position: absolute;
  left: calc(14.5px * var(--multiplier));
  top: calc(48.1px * var(--multiplier));
  transform: rotate(202.5deg);
}

#triangle6 {
  position: absolute;
  left: calc(0.6px * var(--multiplier));
  top: calc(34.2px * var(--multiplier));
  transform: rotate(247.5deg);
}

#triangle7 {
  position: absolute;
  left: calc(0.6px * var(--multiplier));
  top: calc(15.1px * var(--multiplier));
  transform: rotate(292.5deg);
}

#triangle8 {
  position: absolute;
  left: calc(14.4px * var(--multiplier));
  top: calc(1px * var(--multiplier));
  transform: rotate(337.5deg);
}

#circle1 {
  position: absolute;
  left: calc(24.7px * var(--multiplier));
  top: calc(-1px * var(--multiplier));
}
#circle2 {
  position: absolute;
  left: calc(42.9px * var(--multiplier));
  top: calc(6.6px * var(--multiplier));
}
#circle3 {
  position: absolute;
  left: calc(50.5px * var(--multiplier));
  top: calc(24.7px * var(--multiplier));
}
#circle4 {
  position: absolute;
  left: calc(43px * var(--multiplier));
  top: calc(42.8px * var(--multiplier));
}
#circle5 {
  position: absolute;
  left: calc(24.7px * var(--multiplier));
  top: calc(50.4px * var(--multiplier));
}
#circle6 {
  position: absolute;
  left: calc(6.6px * var(--multiplier));
  top: calc(42.9px * var(--multiplier));
}
#circle7 {
  position: absolute;
  left: calc(-1px * var(--multiplier));
  top: calc(24.8px * var(--multiplier));
}
#circle8 {
  position: absolute;
  left: calc(6.5px * var(--multiplier));
  top: calc(6.6px * var(--multiplier));
}
