.upload-container {
  /* text-align: center; */
  height: 102px;
  width: 220px;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  background: white;
  border:  1px solid #670ff7;
  border-radius: 5px;
}
.image-item {
  display: flex;
  margin: 10px 0;
  /* border: 1px solid black; */
}

.logo-image-thumbnail{
  max-width: 180px;
  max-height: 36px;
}

.image-item__btn-wrapper {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.upload__image-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.upload-button:hover,
.upload-button:active,
.upload-button:focus,
.upload-button {
  margin: 0px;
  font-size: 14px;
  padding: 8px;
  border-radius: 4px;
  margin-left: 0px;
  background: #fff;
  height: 100px;
  width: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #8d8d8d;
  text-align: center;
}



.image-remove-button {

  background: #670ff7;
  color: #fff;
  width: 17px;
  height: 17px;
  font-size: 10px;
  border: 1px solid #670ff7;
  border-radius: 50%;
  font-weight: bold;
  text-align: center;
  vertical-align: bottom;
  margin-bottom: 50px;
  cursor: pointer;
}

.image-remove-button:hover {
  opacity: 0.7;
}

.upload-logo-icon{
  max-width: 17px;
  opacity: 0.3;
  margin-bottom: 5px;
}