div#analytics-holder {
}

select#period-selector {
  font-size: 16px;
  padding: 10px;
  border-radius: 16px;
  width: 210px;
  font-family: "DM Sans", sans-serif;
  border: 2px solid #670ff7;
  color: #670ff7;
  font-weight: 600;
  margin-top: 3vh;
  height: auto;
}

select#period-selector:focus {
  outline: none;
}

div#widget-holder {
  display: flex;
  flex-direction: row;
  margin-top: 5vh;
  justify-content: space-between;
}

div#widget-holder div#overview {
  width: 63%;
}

div#widget-holder div#actions {
  width: 33%;
}

div#actions div#actions-visuals {
  background-color: #f4eeff;
  height: 85%;
  border-radius: 14px;
  padding: 10px;
}

div.widget-title {
  font-size: 14px;
  font-weight: 500;
}

div#widget-card-holder {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

div.metric-card {
  width: 24%;
  display: block;
  margin-top: 5vh;
  border-radius: 14px;
  padding-bottom: 10px;
}

div.icon-circle {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  margin: 0px auto;
  position: relative;
  bottom: 30px;
}

div#icon-circle-first {
  background-image: url("../images/spin_icon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

div#icon-circle-second {
  background-image: url("../images/win_icon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

div#icon-circle-third {
  background-image: url("../images/claim_icon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

div#icon-circle-fourth {
  background-image: url("../images/contact_icon.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 80%;
}

div.metric-card h4 {
  margin: 0px;
  text-align: center;
  font-size: 14px;
  color: #4a4a4a;
  opacity: 0.8;
}

div.metric-card div.count {
  background-color: rgb(255, 255, 255);
  text-align: center;
  width: 60%;
  margin: 0px auto;
  padding: 7px;
  border-radius: 14px;
  margin-top: 13px;
  font-weight: 600;
  font-size: 18px;
}

div.metric-card.yellow {
  background-color: #feb910;
}

div.metric-card.yellow div.icon-circle {
  background-color: rgb(248, 235, 176);
}

div.metric-card.yellow div.count {
  color: #feb910;
}

div.metric-card.green {
  background-color: #6fb8af;
}

div.metric-card.green div.icon-circle {
  background-color: #cbf0ec;
}

div.metric-card.green div.count {
  color: #6fb8af;
}

div.metric-card.red {
  background-color: #ef2b7a;
}

div.metric-card.red div.icon-circle {
  background-color: #ffd2e3;
}

div.metric-card.red div.count {
  color: #ef2b7a;
}

div.metric-card.purple {
  background-color: #670FF7;
}

div.metric-card.purple div.icon-circle {
  background-color: #e3d2ff;
}

div.metric-card.purple div.count {
  color: #670ff7;
}

div#prize-table-holder {
  margin-top: 5vh;
}

table#prize-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px;
}

table#prize-table th {
  text-align: left;
  font-size: 14px;
  color: #9b9b9bb7;
}

table#prize-table td.prize-name {
  font-weight: 500;
  color: #310a70;
}

table#prize-table .col1 {
  width: 30%;
  padding-left: 2vw;
  border-radius: 10px;
}

table#prize-table .others {
  width: 14%;
}

table#prize-table td:first-child {
  padding-left: 2vw;
}

table#prize-table tr.prize-line0 {
  font-size: 14px;
  height: 35px;
  border-radius: 60px;
  background-color: #F4EFFF;
}

table#prize-table tr.prize-line1 {
  font-size: 14px;
  height: 27px;

  border-radius: 14px;
}

div#question-analytics-holder {
  margin-top: 5vh;
}

table { 
  border-spacing:0;
}

td:first-child {
   border-top-left-radius: 19px; 
   border-bottom-left-radius: 19px; 
  }

td:last-child {
  border-top-right-radius: 19px; 
  border-bottom-right-radius: 19px; 
  }

.cards-text {
  color: #fff !important;
  font-weight: bold !important;
  text-shadow: #F4EFFF 0;
}